import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';

export const APP_NAME = 'FETS';
export const API_BASE_URL = 'https://edms-api.fetswallet.com';
//export const API_BASE_URL = 'http://localhost:1511/';
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '';

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#ffffff',
  mobileNav: false,
};
