import {
  DashboardOutlined,
  AppstoreOutlined,
  PaperClipOutlined,
  StarOutlined,
  FileOutlined,
  TeamOutlined,
  ForkOutlined,
  PullRequestOutlined,
  BellOutlined,
  CreditCardOutlined,
  FileImageOutlined
} from '@ant-design/icons';

const navigationConfig = (permissions = []) => {
  const dashBoardNavTree = [{
    key: 'dashboard',
    path: `/dashboard`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.reports'),
    submenu: [
    ],
  }];

  const customersNavTree = [{
    key: 'customers',
    path: `/users`,
    title: 'Users Management',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'users',
        path: `/users`,
        title: 'Customers',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      // {
      //   key: 'pendingdocuments',
      //   path: `/pendingdocuments`,
      //   title: 'Pending Documents',
      //   icon: PaperClipOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      //   hidden: !permissions.includes('view.document')
      // },
      {
        key: 'pendingaddress',
        path: `/pendingaddress`,
        title: 'Pending Address',
        icon: PaperClipOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.document')
      },
      {
        key: 'regionalmanager',
        path: `/regional-manager`,
        title: 'Regional Manager',
        icon: BellOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.regional')
      },
      {
        key: 'unmatchedimages',
        path: `/unmatchedimages`,
        title: 'Un Matched Images',
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.document')
      },
      {
        key: 'business',
        path: `/business`,
        title: 'Agents',
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.document')
      }
    ],
  }];
  const systemNavTree = [{
    key: 'system',
    path: `/system/users`,
    title: 'System',
    icon: AppstoreOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.team') && !permissions.includes('view.role'),
    submenu: [
      {
        key: 'system-users',
        path: `/system/users`,
        title: 'Admin Management',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.team'),
      },
      {
        key: 'system-roles',
        path: `/system/roles`,
        title: 'Roles/Permissions',
        icon: ForkOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.role')
      },
      {
        key: 'regional-manager',
        path: `/system/manager`,
        title: 'RM Setup',
        icon: StarOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.role')
      },
      {
        key: 'region',
        path: `/region`,
        title: 'Regional Setup',
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.role')
      },
      {
        key: 'audit',
        path: `/audit`,
        title: 'Audits',
        icon: PullRequestOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.audit')
      }
    ],
  }];

  const reportNavTree = [{
    key: 'report',
    path: `/reports`,
    title: 'Report',
    icon: FileOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.reports'),
    submenu: [
      {
        key: 'transactions',
        path: `/transactions`,
        title: 'Transactions',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.reports')
      },
    ],
  }];

  return [
    ...dashBoardNavTree,
    ...customersNavTree,
    ...systemNavTree,
    ...reportNavTree
  ]
};

export default navigationConfig;
