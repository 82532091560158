import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'


export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={'/dashboard'} component={lazy(() => import(`./dashboards`))} />
        <Route path={'/users'} component={lazy(() => import(`./users`))} />
        <Route path={'/business'} component={lazy(() => import(`./business`))} />
        <Route path={'/pendingdocuments'} component={lazy(() => import(`./pendingdocuments`))} />
        <Route path={'/pendingaddress'} component={lazy(() => import(`./address`))} />
        <Route path={'/region'} component={lazy(() => import(`./region`))} />
        <Route path={'/regional-manager'} component={lazy(() => import(`./regional-manager`))} />
        <Route path={'/transactions'} component={lazy(() => import(`./wallet`))} />
        <Route path={'/unmatchedimages'} component={lazy(() => import(`./unmatchedimages`))} />
        <Route path={'/profile'} component={lazy(() => import(`./profile`))} /> 
        <Route path={'/system/users'} component={lazy(() => import(`./system/users`))} />
        <Route path={'/system/roles'} component={lazy(() => import(`./system/roles`))} />
        <Route path={'/system/manager'} component={lazy(() => import(`./system/regional`))} />
        <Route path={'/audit'} component={lazy(() => import(`./audit`))} />
        <Route path={''} component={lazy(() => import(`./components/feedback/result/Result404`))}/>
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
